<script setup>
  import { useSlots } from 'vue';

  const props = defineProps({
    color: {
      type: String,
      required: false,
      default: 'danger',
      validator(value) {
        return ['danger', 'success', 'information'].includes(value);
      },
    },
    prefix: {
      type: String,
      required: false,
      default: 'Oops!',
    },
  });

  const classes = ['credere-alert', `color--${props.color}`];
  const slots = useSlots();
</script>

<template>
  <section :class="classes">
    <span class="credere-alert-icon">!</span>

    <div class="credere-alert-paragraph">
      <slot>
        <p>
          <b class="credere-alert-prefix">
            {{ prefix }}
          </b>

          Algo deu errado. Por favor, verifique os campos preenchidos e tente novamente.

          <span
            v-if="slots.extra"
            class="credere-alert-extra"
          >
            <slot name="extra" />
          </span>
        </p>
      </slot>
    </div>
  </section>
</template>

<style lang="scss">
  .credere-alert {
    --background-color: var(--token-color-constant-dark);
    --border-color: var(--token-color-alert-danger);
    --border-style: solid;
    --border-width: 0 0 0 var(--token-spacing-xxxs);
    --border-radius: var(--token-border-radius-small);
    --color: var(--token-color-constant-light);
    --font-size: var(--token-font-size-m);
    --gap: var(--token-spacing-xxxs);
    --line-height: var(--token-line-height-l);
    --margin: 0 0 var(--token-spacing-xl) 0;
    --padding: var(--token-spacing-xxs);

    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    @container (max-width: 768px) {
      --font-size: var(--token-font-size-s);
    }

    &.color--success {
      --border-color: var(--token-color-alert-success);
    }

    &.color--information {
      --border-color: var(--token-color-alert-information);
    }

    b {
      --font-weight: var(--token-font-weight-bold);
    }
  }

  .credere-alert-icon {
    --background-color: var(--token-color-alert-danger);
    --border-radius: var(--token-border-radius-circular);
    --color: var(--token-color-constant-dark);
    --font-size: var(--token-font-size-s);
    --font-weight: var(--token-font-weight-bold);
    --margin: 0.25rem 0 0;

    flex-shrink: 0;
    height: 1rem;
    line-height: 1rem;
    text-align: center;
    width: 1rem;

    .color--success & {
      --background-color: var(--token-color-alert-success);
    }

    .color--information & {
      --background-color: var(--token-color-alert-information);
    }
  }

  .credere-alert-prefix {
    --font-weight: var(--token-font-weight-bold);
    --margin: 0 var(--token-spacing-mini) 0 0;
  }

  .credere-alert-paragraph {
    display: block;
  }

  .credere-alert-extra {
    --font-size: var(--token-font-size-s);
    --margin: var(--token-spacing-s) 0 0;

    display: block;
  }
</style>
