export default function referrer() {
  let _referrer;

  try {
    _referrer = new URL(window.CREDERE._INTERNAL?.referrer).href;
  } catch (err) {
    _referrer = window.location.href;
  }

  if (_referrer) {
    _referrer = _referrer.replace(/&amp;/g, '&');
  }

  window.CREDERE._INTERNAL = window.CREDERE._INTERNAL || {};
  window.CREDERE._INTERNAL.referrer = _referrer;
}
