import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowLeft,
  faArrowRight,
  faBook,
  faCalculator,
  faCheck,
  faCheckDouble,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleDot,
  faCircleExclamation,
  faCircleInfo,
  faCode,
  faCopy,
  faDownLeftAndUpRightToCenter,
  faDownload,
  faFileCirclePlus,
  faFileLines,
  faHashtag,
  faLink,
  faMagnifyingGlass,
  faMinus,
  faPen,
  faPlus,
  faRotateLeft,
  faSearch,
  faShare,
  faShareNodes,
  faSquare,
  faSquareCheck,
  faSliders,
  faTimes,
  faTrash,
  faTriangleExclamation,
  faUpRightAndDownLeftFromCenter,
  faWindowMinimize,
} from '@fortawesome/free-solid-svg-icons';

import {
  faCircle as faCircleRegular,
  faCopy as faCopyRegular,
  faSquare as faSquareRegular,
  faWindowMaximize,
} from '@fortawesome/free-regular-svg-icons';

import {
  faWhatsapp as faWhatsApp,
  faFacebookF as faFacebook,
  faInstagram,
  faTiktok as faTikTok,
} from '@fortawesome/free-brands-svg-icons';

library.add(faArrowLeft);
library.add(faArrowRight);
library.add(faBook);
library.add(faCalculator);
library.add(faCheck);
library.add(faCheckDouble);
library.add(faChevronDown);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faChevronUp);
library.add(faCircle);
library.add(faCircleRegular);
library.add(faCircleDot);
library.add(faCircleExclamation);
library.add(faCircleInfo);
library.add(faCode);
library.add(faCopy);
library.add(faCopyRegular);
library.add(faDownLeftAndUpRightToCenter);
library.add(faDownload);
library.add(faFileCirclePlus);
library.add(faFileLines);
library.add(faHashtag);
library.add(faLink);
library.add(faMagnifyingGlass);
library.add(faMinus);
library.add(faPen);
library.add(faPlus);
library.add(faRotateLeft);
library.add(faSearch);
library.add(faShare);
library.add(faShareNodes);
library.add(faSquare);
library.add(faSquareCheck);
library.add(faSquareRegular);
library.add(faSliders);
library.add(faTimes);
library.add(faTrash);
library.add(faTriangleExclamation);
library.add(faUpRightAndDownLeftFromCenter);
library.add(faWindowMaximize);
library.add(faWindowMinimize);

library.add(faFacebook);
library.add(faInstagram);
library.add(faTikTok);
library.add(faWhatsApp);
